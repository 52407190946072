import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import moment from 'moment';
import * as _ from 'lodash';

import {
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'

import ActionDialog from '../ActionDialog';
import { paperStyle } from '../../styles';
import Title from "../Title";
import PeriodPicker, { formatDate } from "../PeriodPicker";
import PayoutTable from './PayoutTable'

import {
  getMonthly,
  getWeekly,
  processPayouts,
} from '../../actions/payouts';
import { getSiteShares, siteAPIGet } from '../../actions/sites';
import { setGlobalAlert } from '../../actions';
import { hasAdmin } from '../../lib';
import { getMonthlySharesURL } from '../../lib/api';
import { getAffiliates } from '../../actions/affiliates';

const PROCESS_BATCH_SIZE = 4;

function ProcessDialog(props) {
  const { data, processing, ...other } = props;
  const count = (data || []).length;
  return (
    <ActionDialog
      title={`Process ${count} Payout${count === 1 ? '' : 's'}`}
      message='Are you sure you want to process payouts for these affiliates?'
      submitLabel='Process'
      disableSubmit={processing}
      disableCancel={processing}
      {...other} >
      {processing && <LinearProgress />}
    </ActionDialog>
  );
}

export default function Payouts(props) {
  const { isProcess, affiliateId } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const site = useSelector(state => state.sites.get('site'));
  const isAdmin = useSelector(state => hasAdmin(state.user.getIn(['user', 'role'])));

  const [periodType, setPeriodType] = useState("monthly");
  const [periodDate, setPeriodDate] = useState(formatDate(moment(), periodType));
  const [data, setData] = useState(null);
  const [sharesEarned, setSharesEarned] = useState(null);
  const [loading, setLoading] = useState(false);
  const [affiliatesData, setAffiliates] = useState(null);

  async function getShareData() {
    setSharesEarned(null);
    let shares = null;

    try {
      const resp = await dispatch(getSiteShares());
      shares = resp.shares;
    }
    catch (error) {
      console.error("failed to get site shares", error);
      return Promise.reject('failed to get site shares');
    }

    if (!shares.length)
      return Promise.resolve('No shares');;

    const reqs = _.map(shares, s => {
      const url = getMonthlySharesURL(site.id) + `/${s.id}`;
      return dispatch(siteAPIGet(url, { period: periodDate }));
    });

    let resps = null;
    try {
      // TODO this should be the following when API is fixed
      // resps = await Promise.all(reqs);
      resps = _.map(_.filter(await Promise.allSettled(reqs), r => r.status === 'fulfilled'), 'value');
    } catch (error) {
      console.error("Error getting affiliate share data", error);
      return Promise.reject("Error getting affiliate share data");
    }

    const res = {};
    _.forEach(resps, ({ share }) => {
      _.forEach(share?.affiliates, a => {
        res[a.affiliateId] = (res[a.affiliateId] || 0) + a.shareEarnedPrice;
      });
    });
    setSharesEarned(res);
    return Promise.resolve(res);
  }

  async function fetchCommissions() {
    setData([]);

    const params = { period: periodDate };
    if (isProcess)
      params.processed = false;

    if (affiliateId)
      params.affiliate = affiliateId

    const reqFunc = periodType === 'monthly' ? getMonthly : getWeekly;

    try {
      const { commissions } = await dispatch(reqFunc(params));
      setData(commissions);
      return Promise.resolve(commissions);
    } catch (error) {
      console.error("Error getting commissions", error);
    }
  };

  async function fetchData() {
    setLoading(true);

    // let affResp = null;
    try {
      const affResp = await dispatch(getAffiliates());
      setAffiliates(affResp.affiliates);
    }
    catch (error) {
      console.warn("Error fetching affiliates:", error);
    }
  
    const proms = [fetchCommissions()];
    if (periodType === 'monthly') {
      proms.push(getShareData());
    } else {
      setSharesEarned(null);
    }

    await Promise.all(proms);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [site, periodType, periodDate, isProcess, affiliateId]); // eslint-disable-line react-hooks/exhaustive-deps

  // process payouts logic

  const [processData, setProcessData] = useState(null);
  const [processing, setProcessing] = useState(false);

  async function doProcess() {
    setProcessing(true);

    const batches = _.chunk(processData, PROCESS_BATCH_SIZE);
    let success = true;

    /* TODO This would be how you can dispatch all the batches of updates in parallel and wait for the
     * results
     *
     * const promises = batches.map(b => dispatch(processPayouts(b, periodType)));
     * try {
     *   const results = await Promise.all(promises);
     * } catch (error) {...}
     */
    console.log('Batches:', batches.length)
    for (let i = 0; i < batches.length; i++) {
      try {
        await dispatch(processPayouts(batches[i], periodType));
      }
      catch (error) {
        console.error(error);
        success = false;
        break;
      }
    }

    setProcessing(false);

    if (success) {
      dispatch(setGlobalAlert('Successfully processed payouts!'));
      fetchData();
      setProcessData(null);
    }
    else {
      dispatch(setGlobalAlert('Failed to process payouts!', 'error'));
    }
  }

  return (
    <>
      <ProcessDialog
        data={processData}
        open={Boolean(processData)}
        processing={processing}
        onSubmit={doProcess}
        onCancel={() => setProcessData(null)} />

      <div className={classes.paper}>
        <Title>{isProcess ? "Process Payouts" : "Payout History"}</Title>
        <PeriodPicker
          onDateChange={setPeriodDate}
          onTypeChange={setPeriodType}
          type={periodType}
          date={periodDate}
          disabled={loading} />
      </div>

      {loading ?
        <LinearProgress className={classes.progress} />
        :
        <PayoutTable
          rows={data || []}
          affiliatesData={affiliatesData}
          sharesEarned={sharesEarned}
          periodType={periodType}
          allowSelect={isAdmin && isProcess}
          onProcess={payouts => {
            setProcessData(_.map(payouts, obj => _.pick(obj, ['affiliateId', 'periodCode'])));
          }} />
      }
    </>
  );
}

Payouts.propTypes = {
  isProcess: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
