import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

import LaunchIcon from '@material-ui/icons/Launch';
import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';
import { MuiThemeProvider, useTheme } from '@material-ui/core';

function useSite() {
  return useSelector(state => state.sites.get('site'));
}

export default function PayoutTable(props) {
  const { affiliatesData, rows, sharesEarned, periodType, allowSelect, onProcess, ...other } = props;

  const site = useSite();

  const data = _.map(_.values(_.groupBy(rows, "affiliateId")), arr => {

    arr[0]['name'] = '---';
    arr[0]['familyName'] = '---';
    if(affiliatesData) {
      const affiliate = affiliatesData.find(affiliates => affiliates.id === arr[0].affiliateId);
    
      if(affiliate) {
        arr[0]['name'] = affiliate.name;
        arr[0]['familyName'] = affiliate.familyName;
      }
    }

    if (periodType === "monthly") {
      const r = {
        ...arr[0],
        periodEarnings: _.sumBy(arr, 'periodEarnings'),
        periodEarningsPaid: _.sumBy(arr, 'periodEarningsPaid'),
        payout: _.sumBy(arr, 'payout'),
      };

      if (site.payoutPeriod === 'monthly')
        r.periodEarningsPaid = 0;

      if (!_.isNil(sharesEarned)) {
        r.totalShares = sharesEarned[arr[0].affiliateId] || 0;
        if (!r.isProcessed)
          r.payout += r.totalShares;
        else {
          r.payout = 0;
        }
      }
      return r;
    }
    else {
      return {
        ...arr[0],
        periodCommission: _.sumBy(arr, 'periodCommission'),
        baseCommission: _.sumBy(arr, 'baseCommission'),
      };
    }
  });

  const columns = useMemo(() => {
    let newColumns = WEEKLY_COLS;
    if (periodType === 'monthly') {
      newColumns = MONTHLY_COLS;
      let totalSharesCol = newColumns.find(column => column.field === 'totalShares');
      if (totalSharesCol) {
        totalSharesCol.hidden = _.isNil(sharesEarned);
        totalSharesCol.hiddenByColumnsButton = _.isNil(sharesEarned);
      }
    }
    return newColumns;
  }, [periodType, sharesEarned]);

  // make table header and checkboxes blue instead of orange
  const theme = useTheme();
  theme.palette.secondary = theme.palette.primary;

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        title="Process Payouts" //TODO {isProcess ? "Process Payouts" : "Payout History"}
        columns={columns}
        data={data}
        options={{
          idSynonym: 'affiliateId',
          showTitle: false,
          searchFieldAlignment: 'left',
          exportAllData: true,
          filtering: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500],
          tableLayout: "fixed",
          padding: "dense",
          draggable: false,
          emptyRowsWhenPaging: false,
          selection: allowSelect,
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, "Payouts")
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, "Payouts")
          }]
        }}
        localization={{
          toolbar: {
            nRowsSelected: '{0} row(s) selected ', //TODO need space at the end of the string and background color sucks
          },
        }}
        actions={allowSelect ? [{
          tooltip: 'Process selected Affiliates...',
          icon: LaunchIcon,
          onClick: (evt, data) => onProcess(data),
        }] : null}
        {...other} />
    </MuiThemeProvider>
  );
}

PayoutTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    affiliateId: PropTypes.number.isRequired,
  })),
  // mapping of affiliateId to total sharesEarned
  sharesEarned: PropTypes.objectOf(PropTypes.number),
  allowSelect: PropTypes.bool,
  periodType: PropTypes.oneOf(["monthly", "weekly"]),

  onProcess: PropTypes.func,
}

const MONTHLY_COLS = [{
  title: 'Affiliate ID',
  field: 'affiliateId',
  type: 'numeric',
  align: "left",
  render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.affiliateId }}>{rowData.affiliateId}</Link>,
}, {
  title: 'First Name',
  field: 'name',
  type: 'text',
  align: "left",
  headerStyle: {
    'textAlign': 'center'
  },
}, {
  title: 'Last Name',
  field: 'familyName',
  type: 'text',
  align: "left",
  headerStyle: {
    'textAlign': 'center'
  },
}, {
  title: 'Total Comp',
  field: 'periodEarnings',
  type: 'currency',
  align: "right",
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Total Paid',
  field: 'periodEarningsPaid',
  type: 'currency',
  align: "right",
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Total Shares',
  field: 'totalShares',
  type: 'currency',
  align: "right",
  hidden: true,
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Amount Due',
  field: 'payout',
  type: 'currency',
  align: "right",
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Period Start',
  field: 'periodStart',
  type: 'date',
  align: "center",
  overflow: 'nowrap',
  headerStyle: {
    'textAlign': 'center',
  },
}, {
  title: 'Period End',
  field: 'periodEnd',
  type: 'date',
  align: "center",
  overflow: 'nowrap',
  headerStyle: {
    'textAlign': 'center',
  },
}, {
  title: 'Status',
  field: 'isProcessed',
  type: 'boolean',
  render: rowData => (rowData.isProcessed === true ? 'processed' : 'pending'),
}];

const WEEKLY_COLS = [{
  title: 'Affiliate ID',
  field: 'affiliateId',
  type: 'numeric',
  align: "left"
}, {
  title: 'First Name',
  field: 'name',
  type: 'text',
  align: "left",
  headerStyle: {
    'textAlign': 'center'
  },
}, {
  title: 'Last Name',
  field: 'familyName',
  type: 'text',
  align: "left",
  headerStyle: {
    'textAlign': 'center'
  },
}, {
  title: 'Total Comp',
  field: 'periodCommission',
  type: 'currency',
  align: "right",
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Amount Due',
  field: 'baseCommission',
  type: 'currency',
  align: "right",
  headerStyle: {
    'textAlign': 'center'
  }
}, {
  title: 'Period Start',
  field: 'periodStart',
  type: 'date',
  align: "center",
  overflow: 'nowrap',
  headerStyle: {
    'textAlign': 'center',
  },
}, {
  title: 'Period End',
  field: 'periodEnd',
  type: 'date',
  align: "center",
  overflow: 'nowrap',
  headerStyle: {
    'textAlign': 'center',
  },
}, {
  title: 'Status',
  field: 'isProcessed',
  type: 'boolean',
  render: rowData => (rowData.isProcessed === true ? 'processed' : 'pending'),
}];
